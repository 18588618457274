<template>
  <v-row
    v-if="payment_done || expense.pending === 0"
    style="overflow: hidden"
    id="token_payment"
  >
    <v-img height="900px" src="@/assets/background.jpg"
      ><v-row
        justify="center"
        align="center"
        :style="
          $vuetify.breakpoint.mdAndUp
            ? 'margin-top:8%'
            : $vuetify.breakpoint.lgAndDown
            ? 'margin-top: 5%'
            : ''
        "
      >
        <v-col cols="8" sm="5" md="2">
          <img width="100%" src="@/assets/logo.png" />
        </v-col>
      </v-row>
      <v-row justify="center"
        ><v-col cols="12" md="6" class="pa-0" src="@/assets/background.jpg">
          <h1
            v-if="expense.pending === 0"
            style="
              text-transform: uppercase;
              color: white !important;
              font-size: 22px;
            "
            v-html="$t('expenses.already_payed')"
          ></h1>
          <h1
            v-else
            style="
              text-transform: uppercase;
              color: white !important;
              font-size: 22px;
            "
          >
            {{ $tc("expenses.payed") }}<br />
          </h1>
        </v-col>
      </v-row>
    </v-img>
  </v-row>
  <tattooer-card v-else>
    <template slot-scope="{}">
      <template v-if="expense">
        <payment :expense_id="$route.params.id" @payed="payment_done = true" />
        <!--<v-stepper v-else v-model="e1" alt-labels>
          <v-stepper-header>
            <v-spacer />
            <v-stepper-step step="1" :complete="e1 > 1"
              ><div
                style="
                  text-align: center;
                  color: var(--v-primary-base);
                  text-transform: uppercase;
                "
              >
                {{ $t("appointments.info_cliente") }}
              </div>
            </v-stepper-step>
            <v-divider style="margin-top: 4em"></v-divider>
            <v-stepper-step step="2" :complete="e1 > 2"
              ><div
                style="
                  text-align: center;
                  color: var(--v-primary-base);
                  text-transform: uppercase;
                "
              >
                payment
              </div>
            </v-stepper-step>
            <v-spacer />
          </v-stepper-header>
          <v-tepper-items>
            <v-stepper-content step="1">
              <form
                action
                data-vv-scope="facturation-customer-form"
                @submit.prevent="validateForm('facturation-customer-form')"
              >
                <v-row justify="center">
                  <v-col cols="8">
                    <v-row>
                      <v-col cols="12" md="4" class="py-0">
                        <label>{{ $t("first_name") }}</label>
                        <v-text-field
                          outlined
                          dense
                          v-model="customer.user.first_name"
                          data-vv-name="first_name"
                          :error-messages="
                            errors.collect(
                              'facturation-customer-form.first_name'
                            )
                          "
                          v-validate="'required'"
                        />
                      </v-col>
                      <v-col cols="12" md="4" class="py-0">
                        <label>{{ $t("last_name") }}</label>
                        <v-text-field
                          outlined
                          dense
                          v-model="customer.user.last_name"
                          data-vv-name="last_name"
                          :error-messages="
                            errors.collect(
                              'facturation-customer-form.last_name'
                            )
                          "
                          v-validate="'required'"
                        />
                      </v-col>
                      <v-col cols="12" md="4" class="py-0">
                        <label>{{ $t("email") }}</label>
                        <v-text-field
                          outlined
                          dense
                          v-model="customer.user.email"
                          data-vv-name="email"
                          :error-messages="
                            errors.collect('facturation-customer-form.email')
                          "
                          v-validate="'required'"
                        />
                      </v-col>
                      <v-col cols="12" md="4" class="py-0">
                        <label>{{ $t("phone") }}</label>
                        <v-text-field
                          outlined
                          dense
                          v-model="customer.user.phone"
                          data-vv-name="phone"
                          :error-messages="
                            errors.collect('facturation-customer-form.phone')
                          "
                          v-validate="'required'"
                        />
                      </v-col>
                      <v-col cols="12" md="4" class="py-0">
                        <label>{{ $t("customers.birthdate") }}</label>

                        <v-menu
                          ref="menu"
                          tabindex="13"
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          min-width="290px"
                          offset-y
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              outlined
                              block
                              v-validate="'required'"
                              data-vv-name="birthdate"
                              dense
                              class="inputs pointer"
                              :value="
                                customer.birthdate
                                  ? $d(
                                      new Date(customer.birthdate),
                                      'dateShort'
                                    )
                                  : ''
                              "
                              color="white"
                              :error-messages="
                                errors.collect(
                                  'facturation-customer-form.birthdate'
                                )
                              "
                              readonly
                              v-on="on"
                            />
                          </template>

                          <v-date-picker
                            first-day-of-week="1"
                            v-model="customer.birthdate"
                            color="primary"
                            ref="picker"
                            data-vv-name="birthdate"
                            class="inputs"
                            scrollable
                            :show-current="today"
                            no-title
                            @input="menu = false"
                            :max="today"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <div class="pa-3 text-right">
                      <v-btn
                        color="primary"
                        type="submit"
                        style="height: 30px"
                        elevation="0"
                        class="ml-2"
                      >
                        {{ $t("next") }}
                        <v-icon>mdi-arrow-right</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </form>
            </v-stepper-content>
            <v-stepper-content step="2">
              <payment v-if="pi" :pi="pi" @payed="payment_done = true" />
            </v-stepper-content>
          </v-tepper-items>
        </v-stepper>-->
      </template>
    </template>
  </tattooer-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import constants from "@/constants";

export default {
  name: "Payment",
  data() {
    return {
      expense: null,
      e1: 1,
      pi: null,
      customer: { user: { role_id: constants.roles.CUSTOMER } },
      payment_done: false,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    ...mapActions("expenses", ["getExpense", "getExpenseToPayWithCustomer"]),
    fetch() {
      this.getExpense(this.$route.params.id).then((result) => {
        this.expense = result;
      });
    },
    async validateForm(scope) {
      this.errors.clear();
      let form_val = await this.$validator.validateAll(scope);
      if (form_val) {
        this.getExpenseToPayWithCustomer({
          id: this.$route.params.id,
          formData: this.customer,
        }).then((resposne) => {
          this.pi = resposne.pi;
          this.e1++;
        });
        return true;
      }
      return false;
    },
  },
  components: {
    TattooerCard: () => import("@/components/inscription/tattooer/Card"),
    payment: () => import("@/components/inscription/customer/Payment"),
  },
};
</script>
<style scoped lang="sass">
label
  display: block
  height: 25px
  color: var(--v-primary-base) !important
</style>

<style lang="sass">
#token_payment
  .v-responsive__sizer
    display: none !important
</style>